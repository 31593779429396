


//  Respond Below
@mixin respond-below($breakpoint) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}


// Respond Above
@mixin respond-above($breakpoint) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
        @media (min-width: $breakpoint-value) {
            @content;
        }
    }
    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}
