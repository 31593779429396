/**
 * @author    Rami jegham
 * @contact   rami.jegham@gmail.com
 */

@import 'bower_components/bootstrap/scss/functions';
@import 'variables';
@import 'helpers';
@import 'bower_components/bootstrap/scss/bootstrap';

/* ==========================================================================
   Base Styles
   ==========================================================================
*/

@import 'base';

/**
 * Additonal Styles
 */


h1,h2{
    color: $primary;
}
h1{
    font-size: 46px;
}
h2{
    font-size: 36px;
}
body{
    background: url("../images/bg.jpg") center;
    background-size: cover;
}

.btn{
    padding: 0.5rem 1.5rem 0.24rem ;
    line-height: 22px;
    border-radius: 30px;
}

.navbar{
    padding: 15px 0;
    background-color: #FFF;
    border-bottom: #dcdcdc 1px solid;
    img{
        max-height: 100px;
    }
}

.has-error{
    .form-control{
        border-color: $danger;
    }
}

.form-group{
    margin-bottom: 30px;
    .output{
        color: #8bc34a;
        font-weight: bold;
    }
}
label:not(.custom-control-label){
    font-size: 20px;
    font-weight: bold;
}

.slider.slider-horizontal{
    width: calc(100% - 30px);
    margin: 0px 15px;
    .slider-selection.tick-slider-selection{
        background: #00ACCE;
    }
    .slider-handle{
        background: #2a3a77;
        border: 3px solid #ffffff;
        box-shadow: 0 0 10px rgba(43, 83, 134, 0.3);
        width: 25px;
        height: 25px;
        margin-left: -12.5px;
        margin-top: -3.5px;
        transition: transform 0.2s ease-out;
        &:hover{
            transform: scale(1.2);
        }
    }
    .slider-tick.in-selection{
        background: #00ACCE;
    }
    .slider-tick-label{
        opacity: 0.6;
        &.label-in-selection{
            opacity: 1;
            font-weight: bold;
        }
    }

}


.section-padding{
    padding: 60px 0;
}

.arabic-text{
    text-align: right;
    direction: rtl;
}


.navbar{
    &-brand{
        @include respond-below(md){
            width: 120px;
        }
    }
    .ans{
        width: 80px;
        @include respond-below('md') {
            width: 60px;
        }
        margin-left: 20px;
    }
    &-toggler{
        background: #68a047;
        &-icon{
            background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255,255,255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
        }
    }
}

#banner{
    @include respond-below('md'){
        height: calc(100vh - 90px );
    }
    .row,
    .container-fluid{
        height: 100%;
    }
    .text-right{
        padding: 100px 10% 0 0;
        height: 100%;
        @include respond-below('lg'){
            padding: 30px 5% 0 0;
        }
        @include respond-below('md'){
            background: url('../images/banner-img-mobile.png') no-repeat left center;
            background-size: 260px;
            padding: 20px 15px 0;
        }
    }
    .buttons{
        margin-top: 100px;
        @include respond-below('lg'){
            margin-top: 50px;
        }
        @include respond-below('md'){
            margin-top: auto;
            margin-bottom: 20px;
        }
    }
    h1{
        font-weight: normal;
        font-size: 100px;
        .big{
            font-size: 120px;
        }
        @include respond-below('xl'){
            font-size: 80px;
            .big{
                font-size: 95px;
            }
        }
        @include respond-below('lg'){
            font-size: 60px;
            .big{
                font-size: 75px;
            }
        }
        @include respond-below('md'){
            font-size: 34px;
            .big{
                font-size: 42px;
            }
        }
    }
    a{
        display: inline-block;

        img{
            max-width: 300px;
            @include respond-below('xl'){
                max-width: 250px;
            }
            @include respond-below('md'){
                max-width: 190px;
            }
        }
        &:hover{
            transform: scale(1.05);
        }
    }
}

.box-link{
    text-align: center;
    display: block;
    font-size: 25px;
    color: $primary;
    text-decoration: none !important;
    margin-bottom: 30px;
    b{
        display: block;
        font-weight: normal;
    }
    &:hover{
        transform: scale(1.05);
    }
    .icn-wrap{
        margin: 0 auto;
        display: block;
    }

}

.icn-wrap{
    width: 90%;
    @include respond-below('md'){
        width: 190px;
    }
    background: url("../images/cell-icn.png") no-repeat center;
    background-size: contain;
    text-align: center;
    position: relative;
    display: inline-block;
    .icn{
        max-width: 100%;
    }
    .simulator &.smaller{
        width: 200px;
        height: 200px;
        margin-top: -50px;
        margin-left: -30px;
    }
}




.banner-innerpage{
    background: $tertiary;
    padding: 35px 0;
    overflow: hidden;
    margin-bottom: 40px;
    h1{
        color: $white;
        text-transform: uppercase;
        margin: 0;
        font-size: 35px;
        line-height: 35px;
    }
}
.sim-title{
    margin-bottom: 40px;
    margin-top: 20px;
}
.inst-box{
    width: 500px;
    font-weight: bold;
    margin: 50px auto 0;
    border-radius: 10px;
    padding: 20px;
    font-size: 30px;
    text-shadow: 0 0 10px rgba(0,0,0,0.2);
    background-color: #488dc8;
    text-align: center;
    color: $white;
    box-shadow: 0 6px 20px rgba(0,0,0,0.2);
    @include respond-below('md'){
        width: 300px;
        font-size: 24px;
        small{
            display: block;

        }
    }
}



.list-special{
    padding-left: 10px;
    margin-top: 20px;
    margin-left: 10px;
    > li{
        padding-left: 45px;
        margin-bottom: 10px;
        position: relative;
        font-size: 18px;
        font-weight: bold;
        ul{
            margin-top: 10px;
        }
        &:before{
            content: '';
            background: url("../images/list-spe.png") no-repeat;
            background-size: 90%;
            width: 35px;
            height: 21px;
            position: absolute;
            top: 1px;
            left: 0;
        }
    }
}


.temoignages{
    position: relative;
    &:after{
        content: '';
        display: block;
        width: 100%;
        height: 110%;
        position: absolute;
        z-index: 0;
        transform: skewY(-3deg);
        top: 10px;
        background-color: rgba(0,0,0,0.05);
    }
    .container{
        position: relative;
        z-index: 1;
    }
    .testi-box{
        text-decoration: none;
        display: inline-block;
        .txt{
            padding-left: 40px;
            h5{
                text-transform: capitalize;
            }
        }
        img{
            transition: transform 0.2s ease-out;
        }
        &:hover{
            color: $secondary;
            img{
                transform: scale(1.05);
            }
        }
    }
}
#videoModal{
    .modal-dialog{
        margin-top: 100px;
    }
    .modal-body {
        position: relative;
        padding: 0;
    }
    .close {
        position:absolute;
        right:-30px;
        top:0;
        z-index:999;
        font-size:2rem;
        font-weight: normal;
        color:#fff;
        opacity:1;
        @include respond-below('md'){
            right: 10px;
            top: 10px;
            background: $primary;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            display: block;
            line-height: 36px;
        }
    }
}



.footer{
    background-color: #000;
    color: #FFF;
    margin-top: 30px;
    padding: 40px 0;
    position: relative;
    z-index: 2;
    .rs-icon{
        width: 35px;
        margin: 0 4px 10px;
        display: inline-block;
    }
    .copy{
        opacity: 0.8;
        color: #ebe711;
    }
}
.footer-ijara{
    position: relative;
    z-index: 0;
    &:after{
        content: '';
        display: block;
        width: 100%;
        height: 110%;
        position: absolute;
        transform: skewY(-1deg);
        top: 50px;
        z-index: 0;
        background-image: linear-gradient(90deg, #315081, #0c6d9b);
        opacity: 0.9;
    }
    .container{
        position: relative;
        z-index: 1;
    }
}
